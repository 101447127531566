export const variables = {
    // Local
    // frontend: 'http://localhost:3001/',
    // backend: 'http://localhost:3000/'
    
    // Desarrollo
    frontend: 'https://kia-admin-ppm.nxs-dev.com.co/',
    backend: 'https://kia-ppm-api.nxs-dev.com.co/'   

    //Produccion
    // frontend: 'https://prepagado.kia.com.co/',
    // backend: 'https://backendprepagado.kia.com.co/'
}
